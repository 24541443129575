import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getMe } from "../features/auth/authSlice";
import { useAppContext } from "../AppContext";

import NewChat from "./NewChat";
import StopGenerating from "./StopGenerating";
import QuestionContainer from "./QuestionContainer";
import GPTResponseContainer from "./GPTResponseContainer";
import PromptInput from "./PromptInput";
import { toast } from "react-toastify";
import GPTResponseImage from "./GPTResponseImage";
import axios from "axios";
import { PencilLine } from "lucide-react";
import { getUserHistory } from "../features/chats/chatSlice";

import ShareConversationModal from "./modals/ShareConversationModal";
import { updateFiles } from "../features/chats/chatSlice";

const Conversation = () => {
  const dispatch = useDispatch();

  const [isChating, setIsChating] = useState(false);

  const { temprature } = useSelector((state) => state.auth);

  const { inputValue, updateInputValue, gptModel, currentChat } =
    useAppContext();

  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [GPTLoading, setGPTLoading] = useState(false);

  const { loginInfo } = useSelector((state) => state.auth);
  const { chatMessages } = useSelector((state) => state.chat);

  const [messages, setMessages] = useState(chatMessages || []);

  const files = useSelector((state) => state.chat.files);

  if (messages.length === 0 && inputValue !== "") {
    // 10000 milliseconds = 10 seconds
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsChating(true);
    updateInputValue("");

    if (inputValue === "") return;

    dispatch(updateFiles([]));

    // if (!pro) {
    //   if (credit <= 0) {
    //     toast.error('Upgrade to pro to continue using without limits.', {
    //       hideProgressBar: true,
    //       draggable: true,
    //     })
    //     setPricingModalOpen(true)
    //     return
    //   } else {
    //     setcredit((credit) => credit - 1)
    //   }
    // }
    const fileData = files?.length > 0 ? files.map((file) => file.s3Key) : [];

    setMessages((messages) => [
      ...messages,
      { role: "user", content: inputValue, files: fileData },
    ]);
    setGPTLoading(true);
    // let inputmessage = message
    // setMessage('')
    setMessages((messages) => [
      ...messages,
      { role: "assistant", content: "" },
    ]);

    try {
      if (gptModel !== "dalle-3") {
        let apiUrl;
        if (gptModel === "Llama3") {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/LlamaResponse`;
        } else if (gptModel === "claude") {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/getclauderesponse`;
        } else {
          apiUrl = `${process.env.REACT_APP_API_URL}/chatbot/getresponse`;
        }

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${loginInfo?.token}`,
          },
          body: JSON.stringify({
            message: inputValue,
            id: currentChat,
            model: gptModel,
            temprature: temprature,
            ...(gptModel === "vision" && { files: files }),
          }),
        });

        const reader = response.body.getReader();
        let accumulatedText = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          // Process each chunk of the response and accumulate the text
          const chunkText = new TextDecoder("utf-8").decode(value);
          accumulatedText += chunkText;

          // Update the last message with the accumulated text
          if (response.status === 200) {
            setMessages((messages) => [
              ...messages.slice(0, -1),
              {
                role: "assistant",
                content: accumulatedText,
              },
            ]);
          } else {
            let eresp = JSON.parse(accumulatedText);
            eresp = eresp.message;
            setMessages((messages) => [
              ...messages,
              { role: "assistant", content: eresp },
            ]);
            break;
          }

          // Optional: handle scrolling down if needed
          // handleScrollDown();
        }
      } else {
        setMessages((messages) => [
          ...messages.slice(0, -1),
          {
            role: "assistant",
            content: "Generating Image...",
          },
        ]);

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/chatbot/generateImage`,
            {
              message: inputValue,
              id: currentChat,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${loginInfo?.token}`,
              },
            }
          );

          setMessages((messages) => [
            ...messages.slice(0, -1),
            {
              role: "assistant",
              content: "imageURL",
              imageUrl: response.data.imageUrl,
            },
          ]);
        } catch (error) {
          console.error("Error generating image:", error);
          // Handle error, possibly update state with error message
          setMessages((messages) => [
            ...messages.slice(0, -1),
            {
              role: "assistant",
              content: "Failed to generate image.",
            },
          ]);
        }
      }
      setGPTLoading(false);

      if (messages.length === 0) {
        dispatch(getUserHistory(loginInfo.token));
      }

      dispatch(getMe(loginInfo?.token));
      // updateCredit()
    } catch (err) {
      alert(err);

      // Display toast error instead of writing to messages
      // toast.error('An error occurred. Please try again later.', {
      //   hideProgressBar: true,
      //   draggable: true,
      // })

      // Optionally, you can also perform additional error handling or logging here
    }
  };

  const bottomRef = useRef(null);

  useEffect(() => {
    if (chatMessages) {
      setMessages(chatMessages);
    }

    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleShareConversation = () => {
    setModalType("ShareConvo");
    setShowModal(true);
  };

  return (
    <div className="flex flex-col overflow-auto h-full ">
      <div className="flex justify-end">
        {" "}
        {/* This div wraps your button and pushes it to the right */}
        {messages?.length !== 0 && (
          <button
            onClick={handleShareConversation}
            className="text-textColor flex items-center justify-between px-3 py-3 mx-2 my-3 rounded-lg font-[500] border-2 border-borderColor"
            // onClick={addPrompt}
            style={{ width: "auto" }}
          >
            Share Chat
          </button>
        )}
      </div>
      {/* <div
  className={`max-w-[1300px] mx-auto pb-[10rem] flex flex-col ${
    messages.length === 0 ? "h-full" : ""
  } lg:min-w-[1300px]`}
> */}
      <div
        className={`mx-auto pb-[10rem] flex flex-col ${
          messages.length === 0 ? "h-full" : ""
        } w-full  max-w-[850px]`}
      >
        {messages?.length !== 0 ? (
          <>
            <div className="px-5 pt-5 flex flex-col justify-start gap-6">
              {messages?.map((chat, index) => {
                // Check if the chat includes an image URL or image key
                if (chat.imageUrl || chat.imageKey) {
                  return (
                    <GPTResponseImage
                      key={index}
                      imageURL={chat.imageUrl}
                      imageKey={chat.imageKey}
                    />
                  );
                } else {
                  // Fallback to the original condition for text-based content
                  return chat.role === "user" ? (
                    <QuestionContainer
                      key={index}
                      content={chat.content}
                      files={chat.files ? chat.files : []}
                    />
                  ) : (
                    chat.content !== "" && (
                      <GPTResponseContainer
                        key={index}
                        content={chat.content}
                      />
                    )
                  );
                }
              })}
            </div>
            <div ref={bottomRef} />
          </>
        ) : (
          <NewChat />
        )}
      </div>

      <div className="absolute bottom-0 pb-10 w-[100%] bg-componentBg">
        {/* {GPTLoading && <StopGenerating />} */}
        {currentChat !== "" && <PromptInput handleSubmit={handleSubmit} />}
      </div>

      {showModal && (
        <ShareConversationModal
          type={modalType}
          open={showModal}
          setOpen={setShowModal}
          structureId={messages[0].structure}
        />
      )}
    </div>
  );
};

export default Conversation;
