import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LeftSidebar from "../components/LeftSidebar";
import RightSidebar from "../components/RightSidebar";
import Conversation from "../components/Conversation";

import { getMe } from "../features/auth/authSlice";
import { getAds } from "../features/chats/chatSlice";
import MobileNavbar from "../components/MobileNavbar";
import { useSearchParams } from "react-router-dom";

import ReactGA from "react-ga4";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { loginInfo, planSelected } = useSelector((state) => state.auth);

  useEffect(() => {
    const authType = loginInfo ? loginInfo : localStorage.getItem("expiresIn");

    if (!authType) {
      navigate("/");
    }


    if (!loginInfo.user?.isEmailVerified) {
      navigate("/email-verify");
    }
    const checkoutId = searchParams.get("checkout_Id");
    const checkoutStatus = searchParams.get("checkout_status");
    const checkoutValue = searchParams.get("checkout_value");

    if (checkoutId && checkoutStatus && checkoutValue) {
      // Simplify the event reporting process with a function
      const reportCheckoutEvent = (status, value) => {
        console.log("reportCheckoutEvent", status, value);
        ReactGA.event({
          category: "checkout",
          action: `checkout_${status}`,
          ...(value && { value: parseFloat(value) }), // Only add value if it's provided and checkout is successful
        });
      };

      if (checkoutStatus === "success") {
        reportCheckoutEvent("success", checkoutValue);
      } else {
        reportCheckoutEvent("failed");
      }

      // Clear out parameters from the URL bar
      // Construct a new URL without the specific search parameters
      const url = new URL(window.location);
      url.searchParams.delete("checkout_Id");
      url.searchParams.delete("checkout_status");
      url.searchParams.delete("checkout_value");
      window.history.pushState({}, "", url);
    }

    if (authType) {
      dispatch(getMe(loginInfo && loginInfo?.token));
      dispatch(getAds(loginInfo?.token));
    }

    if (!planSelected) {
      navigate("/subscription-plans");
    }
  }, [loginInfo, planSelected, navigate, dispatch]);

  return (
    <div className="bg-pageBg p-2">
      <MobileNavbar />
      <div className="flex h-screen">
        <div className="hidden lg:block">
          <LeftSidebar collapsible={true} />
        </div>
        <div className="mx-0 md:mx-4 flex-1 overflow-hidden bg-componentBg rounded-3xl relative">
          <Conversation />
        </div>
        <div className="hidden lg:block">
          <RightSidebar collapsible={true} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
