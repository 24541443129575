import React from "react";
import { Check } from "lucide-react";
import axios from "axios";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";


const SubscriptionCard = ({ name, price, type, points,stripeId }) => {

  const {loginInfo} = useSelector((state) => state.auth);

  const subscribe = async () => {
    try {
      const result = await axios.get(`/api/v1/stripe/checkout/${stripeId}`,{
        headers: {
          Authorization: `Bearer ${loginInfo.token}`,
        },
      });
      const checkoutUrl = result.data.session.url;
      
      // Redirect to checkout URL
      localStorage.setItem("planSelected", true);
      window.location.href = checkoutUrl;
    } catch (error) {
      toast.error("Failed to initiate subscription. Please try again.");
      
      console.error("Subscription error:", error);
    }
  };


  return (
    <div className="bg-charcoal flex flex-col w-[18.5rem] md:w-[26rem] rounded-3xl py-7 md:py-5 px-5 md:px-7">
      <div className="flex flex-col items-center text-center gap-3 pb-8 border-b-2 border-gray-400/10">
        <div>
          <h3 className="text-white font-bold text-3xl md:text-4xl">{name}</h3>
          <p
            className={`text-primary mt-1 ${
              type === "" && "opacity-0 cursor-default"
            }`}
          >
            14-Days Free Trial!
          </p>
        </div>

        <div className="flex flex-col items-center gap-7">
          <span className="text-xl flex items-end font-medium text-white">
            $<span className="text-4xl md:text-5xl font-semibold text-white">{price}</span>
            {/* {type && `/${type}`} */}/month
          </span>
          <button onClick={subscribe} className="bg-brightTeal text-lg text-dark rounded-full font-semibold px-[4rem] md:px-[5rem] py-3 transition duration-500 ease-in-out hover:bg-mirage hover:text-primary">
          Start Free Trial
          </button>
          {type == "year" && (
            <p className="text-small text-white">
              When Billed Annually
            </p>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-primary text-xl text-center mt-5">Features</h3>
        <div className="flex flex-col text-white gap-3">
          {points.map((point, index) => (
            <div key={index} className="flex items-center gap-2">
              <Check />
              <p className="text-lg">{point}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
